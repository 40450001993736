import {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import { BANNER_MESSAGE } from '../../constants/strings'
// import classNames from 'classnames'

type Props = {
    isOpen: boolean
}

export const Banner = ({ isOpen }: Props) => {

    if (BANNER_MESSAGE === '') return null

    return (
        <Transition
            show={isOpen}
            as={Fragment}
            enter="ease-out duration-300 transition"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="bg-secondary-color-500">
                <div className="w-full mx-auto py-3 px-3 sm:px-6 lg:px-8">
                    <div id="banner-message" className="text-center">
                        <p className="ml-3 font-light text-black" dangerouslySetInnerHTML={{__html: BANNER_MESSAGE}}></p>
                    </div>
                </div>
            </div>
        </Transition>
    )
}
