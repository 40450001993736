export const WORDS = [
    'cheer',
    'eller',
    'groin',
    'throw',
    'chain',
    'dixon',
    'chant',
    'drive',
    'flags',
    'point',
    'randy',
    'beebe',
    'blair',
    'block',
    'kneel',
    'field',
    'smoot',
    'kluwe',
    'fired',
    'cover',
    'mitch',
    'squad',
    'green',
    'balls',
    'jared',
    'draft',
    'yards',
    'denny',
    'grass',
    'laces',
    'moons',
    'eddie',
    'irvin',
    'hoard',
    'vikes',
    'kwesi',
    'watts',
    'kevin',
    'speed',
    'floyd',
    'route',
    'hovan',
    'glove',
    'clock',
    'ihmir',
    'kicks',
    'punts',
    'donut',
    'ahmad',
    'jetta',
    'trade',
    'woods',
    'brett',
    'stats',
    'choke',
    'leber',
    'spike',
    'lynch',
    'dungy',
    'agent',
    'guard',
    'vigil',
    'wilfs',
    'horns',
    'blitz',
    'walsh',
    'grant',
    'teddy',
    'boone',
    'rival',
    'udeze',
    'squib',
    'fusco',
    'downs',
    'allen',
    'percy',
    'reiff',
    'korey',
    'daily',
    'pylon',
    'north',
    'favre',
    'leroy',
    'pooch',
    'locke',
    'games',
    'drill',
    'smith',
    'tyler',
    'norse',
    'brock',
    'plays',
    'doink',
    'drops',
    'oneil',
    'kalil',
    'diggs',
    'cried',
    'braid',
    'coach'
]
