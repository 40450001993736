import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import {
    INFO_CORRECT_WORD,
    INFO_PRESENT_WORD,
    INFO_ABSENT_WORD
} from '../../constants/strings'
import {InfoMessage} from "./InfoMessage";

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
    const correct = Array.from(INFO_CORRECT_WORD),
        present = Array.from(INFO_PRESENT_WORD),
        absent = Array.from(INFO_ABSENT_WORD)
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value={correct[0]}
          status="correct"
        />
        <Cell value={correct[1]} />
        <Cell value={correct[2]} />
        <Cell value={correct[3]} />
        <Cell value={correct[4]} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter {correct[0]} is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value={present[0]} />
        <Cell value={present[1]} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value={present[2]}
          status="present"
        />
        <Cell value={present[3]} />
        <Cell value={present[4]} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter {present[2]} is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value={absent[0]} />
        <Cell value={absent[1]} />
        <Cell value={absent[2]} />
        <Cell isRevealing={true} isCompleted={true} value={absent[3]} status="absent" />
        <Cell value={absent[4]} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter {absent[3]} is not in the word in any spot.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
          <InfoMessage />
          Created by
          {' '}
          <a
              href="https://www.hannahmariepark.com/"
              className="underline font-bold"
          >
              Hannah Park
          </a>.{' '}
          Check out the {' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
            original code here
        </a>.{' '}
      </p>
    </BaseModal>
  )
}
