import {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import { INFO_MESSAGE } from '../../constants/strings'


export const InfoMessage = () => {

    if (INFO_MESSAGE === '') return null

    return (
        <Transition
            as={Fragment}
            enter="ease-out duration-300 transition"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <span dangerouslySetInnerHTML={{__html: INFO_MESSAGE + '&nbsp;'}}></span>
        </Transition>
    )
}
