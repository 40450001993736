import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import {
  GAME_TITLE,
  GAME_URL,
  GAME_HASHTAGS,
  CORRECT_TILE,
  PRESENT_TILE
} from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean,
  isDarkMode: boolean,
  isHighContrastMode: boolean
) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} ${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? '*' : ''}\n\n` +
      generateEmojiGrid(guesses, getEmojiTiles(isDarkMode, isHighContrastMode)) +
      generateLink()
  )
}

export const generateEmojiGrid = (guesses: string[], tiles: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((_, i) => {
          switch (status[i]) {
            case 'correct':
              return tiles[0]
            case 'present':
              return tiles[1]
            default:
              return tiles[2]
          }
        })
        .join('')
    })
    .join('\n')
}

const getEmojiTiles = (isDarkMode: boolean, isHighContrastMode: boolean) => {
  let tiles: string[] = []
  tiles.push(isHighContrastMode ? '🟧' : `${CORRECT_TILE}`)
  tiles.push(isHighContrastMode ? '🟦' : `${PRESENT_TILE}`)
  tiles.push(isDarkMode ? '⬛' : '⬜')
  return tiles
}

const generateLink = () => {
  let link = ''
  if (GAME_URL !== null) {
    link = `\n\n${GAME_URL}`
  }
  if (GAME_HASHTAGS !== null) {
    const hashtags_arr = GAME_HASHTAGS.split(',')
    let hashtags = []
    for (let tag of hashtags_arr)
    {
      hashtags.push(`#${tag.trim()}`)
    }
    link += `\n${hashtags.join(' ')}`
  }
  return link
}